export const generateBreadcrumbJson = (EITitle, link, homeURL) => {
  const baseURL = "";
  let breadcrumbJson = {
    "breadcrumb": {
      "callback": (function (event, data) {
        console.log(event);
        console.log(data);
        window.location.href = data.attributes.url;
      }),
      "id": "softwareDeveloper",
      "selected": "true",
      "text": "Software Developer",
      "data": {
          "attributes": {
              "url": "https://www.intel.com/content/www/us/en/developer/overview.html"
          }
      },
      "useCallback": true,
      "children": [
        {
          "id": "tools",
          "selected": true,
          "text": "Tools",
          "useCallback": true,
          "data": {
            "attributes": {
              "url": "https://www.intel.com/content/www/us/en/developer/tools/overview.html"
            }
          },
          "children": [
            {
              "id": "devCatalog",
              "selected": true,
              "text": "Intel® DevCatalog",
              "useCallback": true,
              "data": {
                "attributes": {
                  "url": "https://www.intel.com/content/www/us/en/developer/tools/software-catalog/overview.html?s=Newest"
                }
              },
              "href": "",
              "children": [
                {
                  "id": "Ingredient",
                  "selected": true,
                  "text": EITitle,
                  "useCallback": true,
                  "data": {
                    "attributes": {
                      "url": baseURL + homeURL
                    }
                  },
                  "children": link ? [
                    link === "customDownload" && ({
                      "id": "customDownload",
                      "selected": true,
                      "text": "Custom Download",
                      "useCallback": false,
                      "data": {
                        "attributes": {
                          "url": baseURL + "/customdownload"
                        }
                      }
                    }),
                    link === "download" && ({
                      "id": "download",
                      "selected": true,
                      "text": "Download",
                      "useCallback": false,
                      "data": {
                        "attributes": {
                          "url": baseURL + "/downloadrecipe" + homeURL
                        }
                      },
                    }),
                    link === "licenseAgreement" && ({
                      "id": "licenseAgreement",
                      "selected": true,
                      "text": "License Agreement",
                      "useCallback": false,
                      "data": {
                        "attributes": {
                          "url": baseURL + "/licenseagreement" + homeURL
                        }
                      },
                    })
                  ] : ""
                }
              ]

            }
          ]
        }
      ]
    },
    isDropdownDisabled: true
  }
  let arr = breadcrumbJson.breadcrumb.children[0].children[0].children[0].children;
  if (arr)
    arr = arr.filter(item => item);
  breadcrumbJson.breadcrumb.children[0].children[0].children[0].children = arr;
  return breadcrumbJson;
}
