import React, { Component } from "react";
import styles from "./Packages.module.scss";
import { connect } from "react-redux";
import { UpdateEnsightContent, ClearRecipeDetails, UpdateRecipeClass, UpdateNavigatedFromLicenseAgreementPage } from "../../actions/EIAction";
import GridSelection from "../../components/GridSelection/GridSelection";
import Loader from "../../components/loader/loader";
import { withTranslation } from "react-i18next";
import {generateBreadcrumbJson} from "../../components/generateBreadcrumb";
import { HeroBanner } from "../../components/Common/HeroBanner";
import { Button, Column, Grid, Heading } from '@carbon/react';

let data = "visioninsights";
class Recipes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoader: true,
      isLearnMoreRequired: true
    };
  }
  componentDidUpdate(prevProps) {
    const hasAChanged =
      this.props.EIReducer.isLoaded !== prevProps.EIReducer.isLoaded;
    if (hasAChanged) {
      if (this.props.EIReducer.isLoaded === true) {
        this.setState({ showLoader: false });
      } else {
        this.setState({ showLoader: true });
      }
    }
  }
  componentDidMount() {
    let currentURL = window.location.pathname;
    const selectedLanguage = this.props.EIReducer.selectedLanguage;
    const isNavigatedFromLicenseAgreementPage = this.props.EIReducer.isNavigatedFromLicenseAgreementPage
    let splitURL = currentURL.split("/");
    if(isNavigatedFromLicenseAgreementPage){
      data = "Online Distribution"
    }
    else if (splitURL.length > 1) {
      if (splitURL[splitURL.length - 1] !== "") {
        data = splitURL[splitURL.length - 1];
      } else {
        data = splitURL[splitURL.length - 2];
      }
    }
    if (data === "") {
      data = "visioninsights";
    }

    if (data === "edtdt_vision") {
      this.setState({
        isLearnMoreRequired: false
      })
    }    
    this.props.UpdateRecipeClass(data);
    this.props.UpdateNavigatedFromLicenseAgreementPage(false)
    if(this.props.EIReducer.isFetchdataServiceCallRequired){
      this.props.ClearRecipeDetails();      
      this.props.UpdateEnsightContent(data, this.props.history);
    }else{
      this.setState({
        showLoader:false
      })
    }    
    if (window.intel && window.intel.breadcrumbFromJSON) {
      let EITitle = "";
      if (this.props.EIReducer.EITitle) {
        EITitle = this.props.EIReducer.EITitle[selectedLanguage];
        if (EITitle === undefined || EITitle === "") {
          EITitle = this.props.EIReducer.EITitle["en"];
        }
      }
      const breadcrumbJson = generateBreadcrumbJson(EITitle, "");
      window.intel.breadcrumbFromJSON.createFromJson(breadcrumbJson);
      window.intel.breadcrumbbar.initialize();
    }
  }

  

  render() {
    return (
      <div>
       
   
        {this.state.showLoader === true && <Loader />}
        {this.state.showLoader === false &&
        <div id="MainContent" className={this.state.showLoader === true ? styles.hide : styles.main}>
          {!this.props.EIReducer.isNoRecipe && (
            <div>
              <div className = {styles.sectionWrapper}>
                <GridSelection isLearnMoreRequired = {this.state.isLearnMoreRequired} />
              </div>
            </div>
          )}
        </div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {
  UpdateEnsightContent,
  ClearRecipeDetails,
  UpdateRecipeClass,
  UpdateNavigatedFromLicenseAgreementPage
})(withTranslation()(Recipes));
