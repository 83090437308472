import React, { Component } from "react";
//import styles from "./footer.module.css";
import { withTranslation } from "react-i18next";

class Footer extends Component {
  render() {
    window.INTELNAV = window.INTELNAV || {}; window.INTELNAV.renderSettingsFooter={version:"2.0 - 03/12/2017 08:00:00",OutputId:"gf_default"};
    return (
      <div className="footerContainer">
        <div id="recode50footer"></div>
      </div>
    );
  }
}

export default withTranslation()(Footer);
