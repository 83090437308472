import React, { Component, createRef } from "react";
import styles from "./CustomDownload.module.scss";
import "./carousel.scss";
import { withRouter } from "react-router-dom";
import {
  UpdateEnsightContent,
  checkEntitlementAndGenerateUniqueKey,
  UpdateUserSelections,
  UpdateSelectedIngredients,
  UpdateDisableRecipeList,
} from "../../actions/EIAction";
import EIIncludedComponents from "../../components/EIIncludedComponents/EIIncludedComponents";
import { connect } from "react-redux";
// import { Button } from "react-bootstrap";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import LaunchIcon from "../../resources/images/ic_link_76DAFF.svg";
import {generateBreadcrumb} from "../../components/Common/common"
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import sanitizeHtml from 'sanitize-html';

import { withTranslation } from "react-i18next";
import Loader from "../../components/loader/loader";
import InfoOutlinedIcon from "../../resources/images/info_circle.svg";
import { Overlay, Popover } from "react-bootstrap";
import RecommendedImage from "../../resources/images/recommendedImageWhite.PNG";
import _ from "lodash";
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group";
import { Loading, Button, Checkbox } from '@carbon/react';

let $;
let disableCheckBoxList = [];
if (typeof window !== `undefined`) {
  $ = require("jquery");
}
let selectedLanguage = "en";
class CustomDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadIndex: 2,
      open: false,
      checkboxes: {},
      show: false,
      target: "",
      container: "",
      selectedIngredientName: "",
      selectedIngredientDesc: "",
      showLoader: true,
      optInForSubscription: true,
      hideDownloadIcon: true,
      recipeVersion: "",
      selectedOSName: "",
      selecteduseCaseVersion: "",
      selectedIngredientMoreInfoLink: "",
      baseURL: "",
      gradientTopHeight: 0,
      popoverBodyHeight: 160,
    };
    this.initateCarousel = this.initateCarousel.bind(this);
    // this.handleIngredientSelection = this.handleIngredientSelection.bind(this);
    this.defaultDownload = this.defaultDownload.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.disableDependentIngredient =
      this.disableDependentIngredient.bind(this);
    this.addCSSClassToIngredients = this.addCSSClassToIngredients.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleOptInSelection = this.handleOptInSelection.bind(this);
    this.triggerRef = createRef();
    this.containerRef = createRef();
    this.OverlayRef = createRef();
    this.headerRef = React.createRef();

    if (typeof window !== `undefined`) {
      $ = require("jquery");
    }
  }
  componentDidUpdate(prevProps) {
    const hasAChanged =
      this.props.EIReducer.isLoaded !== prevProps.EIReducer.isLoaded;
    if (hasAChanged) {
      if (this.props.EIReducer.isLoaded === true) {
        this.setState({ showLoader: false });
      } else {
        this.setState({ showLoader: true });
      }
    }
    if (this.props.EIReducer.error !== prevProps.EIReducer.error && this.props.EIReducer.error === true) {
      setTimeout(() => {
        this.handleResize();
      }, 300);

    }
  }
  componentDidMount() {
    if (this.props.history.action !== "PUSH") {
      let baseURL = this.props.EIReducer.baseURL;
      this.props.history.push(baseURL);
    } else {
      this.setState({ showLoader: false });
      let wrapperHeight = document.getElementById("ESHWrapper").style.minHeight;
      document.getElementById("MainContent").style.minHeight = wrapperHeight;
      window.addEventListener("resize", this.handleResize);
      $(".ESHWrapperCustom").css("margin", 0);
      setTimeout(() => {
        const euCountryList = [
          "AT",
          "BE",
          "BG",
          "HR",
          "CY",
          "CZ",
          "DK",
          "EE",
          "FI",
          "FR",
          "DE",
          "GR",
          "HU",
          "IE",
          "IT",
          "LV",
          "LT",
          "LU",
          "MT",
          "NL",
          "PL",
          "PT",
          "RO",
          "SK",
          "SI",
          "ES",
          "SE",
        ];
        const userCountry = this.props.EIReducer.session.country;
        if (euCountryList.indexOf(userCountry) >= 0) {
          this.setState({ optInForSubscription: false });
        }
        let osVersions = this.props.EIReducer.osVersions;
        let selectedOSName = "";
        let selectedOS = this.props.EIReducer.selectedOS;
        const selectedLanguage = this.props.EIReducer.selectedLanguage;
        let selectedRecipeVersion = this.props.EIReducer.selectedRecipeVersion;
        let selectedUseCase = this.props.EIReducer.selectedUseCase;
        let useCasesVersions = this.props.EIReducer.useCases;
        let selectedUseCaseName = "";
        osVersions.forEach((osVersion) => {
          if (selectedOS === osVersion.id) {
            selectedOSName =
              osVersion.name[selectedLanguage] !== undefined
                ? osVersion.name[selectedLanguage]
                : osVersion.name["en"];
          }
        });

        if (selectedOSName) {
          this.setState({ selectedOSName: selectedOSName });
        }
        const recipeVersions = this.props.EIReducer.recipeVersions;
        recipeVersions.forEach((recipeVersion) => {
          if (selectedRecipeVersion === recipeVersion.id) {
            this.setState({ recipeVersion: recipeVersion.label });
          }
        });
        useCasesVersions.forEach((useCasesVersion) => {
          if (selectedUseCase === useCasesVersion.id) {
            selectedUseCaseName =
              useCasesVersion.name[selectedLanguage] !== undefined
                ? useCasesVersion.name[selectedLanguage]
                : useCasesVersion.name["en"];
          }
        });
        if (selectedUseCaseName) {
          this.setState({ selecteduseCaseVersion: selectedUseCaseName });
        }
        $(".card-carousel").css({
          //  left: $(".ESHWrapper").outerWidth() / 4,
        });
        this.initateCarousel();
        this.handleResize();
        this.disableDependentIngredient();
        this.disableCheckBoxes();
        this.addCSSClassToIngredients();
        var maxHeight = 0;
        $(".my-card").each(function () {
          if ($(this).height() > maxHeight) {
            maxHeight = $(this).height();
          }
        });
        $(".my-card").height(maxHeight);
      }, 500);
      this.generateBreadcrumbFn();
    }
  }

  generateBreadcrumbFn = () => {    
    if (window.intel && window.intel.breadcrumbFromJSON) {
      const selectedLanguage = this.props.EIReducer.selectedLanguage;
      let EITitle = "";
      if (this.props.EIReducer.EITitle) {
        EITitle = this.props.EIReducer.EITitle[selectedLanguage];
        if (EITitle === undefined || EITitle === "") {
          EITitle = this.props.EIReducer.EITitle["en"];
        }
      }
      let data = { 
        EITitle,      
        pageName: "customDownload",
        history: this.props.history,
        homeURL: this.props.EIReducer.baseURL
      }
      generateBreadcrumb(data);        
    }else {
      setTimeout(() => {
        this.generateBreadcrumbFn();
      }, 1000);
      
    }
  }

  filterIngredientList = (item, query) => {
    for (var key in query) {
      let queryVal = query[key];
      if (typeof (item[key]) === "boolean") {
        if (item[key] === undefined || item[key] !== query[key])
          return false;
      } else if (Array.isArray(queryVal)) {
        let flag = true;
        if (queryVal.length === 0) {
          flag = false;
          return false;
        }
        for (var k in queryVal) {
          if (
            item[key].indexOf(queryVal[k].toString()) === -1 &&
            item[key] !== undefined
          ) {
            flag = false;
            return false;
          }
        }
        return flag;
      } else {
        if (item[key] === undefined || item[key].indexOf(query[key]) < 0)
          return false;
      }
    }
    return true;
  }

  componentWillUnmount() {
    $(".ESHWrapper").css("margin", "");
  }

  closeOverlay() {
    this.setState({
      show: false,
    });
  }

  handleResize() {
    $(".my-card").width($(".ESHWrapper").outerWidth() / 2);
    $(".downloadCard")
      .parent()
      .width($(".ESHWrapper").outerWidth() / 2);
    let selectedCardNumber = $(".my-card.active").attr("id");
    let cardSize = $(".ESHWrapper").outerWidth() / 2;
    let leftValue = ($(".ESHWrapper").outerWidth() / 4) - ((cardSize + 24) * (parseInt(selectedCardNumber) - 1));
    //  $(".card-carousel").css({ left: leftValue });
  }

  disableCheckBoxes() {
    $(".active *").removeAttr("disabled");
    disableCheckBoxList.forEach((checkbox) => {
      if (document.getElementById(checkbox))
        document.getElementById(checkbox).disabled = true;
    });
  }

  addCSSClassToIngredients() {
    let allIngredients = this.props.EIReducer.EIDownloadList;
    allIngredients.forEach((ingredient) => {
      $("#" + ingredient.id)
        .parents(".card-wrapper")
        .removeClass(["disabled", "selected"]);
      if (document.getElementById(ingredient.id)) {
        if (document.getElementById(ingredient.id).disabled === true) {
          $("#" + ingredient.id)
            .parents(".card-wrapper")
            .addClass("disabled");
        } else if (document.getElementById(ingredient.id).checked) {
          $("#" + ingredient.id)
            .parents(".card-wrapper")
            .addClass("selected");
        }
      }
    });
  }

  initateCarousel() {
    $(".card-carousel *").attr("disabled", "disabled");
    $(".active *").removeAttr("disabled");
  }

  defaultDownload() {
		const { EIReducer } = this.props;
		const { optInForSubscription } = this.state;
		const selectedLanguage = EIReducer.selectedLanguage;
		const EITitle = EIReducer.EITitle[selectedLanguage];
		let data = {};
		data.components = [];
		data.images = [];
		data.helmIds = [];
		let selectedIngredients = this.props.EIReducer.selectedIngredientsXML;
		let ircProductId = this.props.EIReducer.ircProductId;
		let selectedRecipe = this.props.EIReducer.selectedRecipeVersion;
		let selectedOSVersion = this.props.EIReducer.selectedOS;
		let recipeClass = this.props.EIReducer.recipeClass;
		var customizeCompleteButton =
			this.props.EIReducer.recipeURL + '-custom-download-complete';
		data.recipeClass = recipeClass;
		data.recipeURL = this.props.EIReducer.recipeURL;
		data.isUserOptIn = optInForSubscription;
		if (selectedIngredients.length) {
      let selectedIngredientsId = [];
			for (var i = 0; i < selectedIngredients.length; i++) {
				if (
					selectedIngredients[i].type === undefined ||
					selectedIngredients[i].type === 'ingredient'
				) {
					selectedIngredientsId.push(selectedIngredients[i].id);
					if (
						selectedIngredients[i].dependencies &&
						selectedIngredients[i].dependencies.length
					) {
						selectedIngredients[i].dependencies.map((e) => {
							selectedIngredientsId.push(e);
						});
					}
				} else if (selectedIngredients[i].type === 'container') {
					data.images.push(selectedIngredients[i].id);
				} else if (selectedIngredients[i].type === 'helmchart') {
					data.helmIds.push(selectedIngredients[i].id);
				}
			}
			let uniqueSelectedIngredientsId = [...new Set(selectedIngredientsId)];
			data.components.push(...uniqueSelectedIngredientsId);
			if (selectedRecipe) {
				data.recipeId = selectedRecipe;
				data.recipeName = EITitle;
				data.ircProductId = ircProductId;
				data.osId = selectedOSVersion;
				data.selectedFilterTag = this.props.EIReducer.selectedFilterOption
					.length
					? this.props.EIReducer.selectedFilterOption
					: null;
				data.downloadType = 'custom';
				data.isProdKeyRequired = this.props.EIReducer.isProductKeyRequired;
			}
			window.wap_tms.aaTrack.do(
				'Components',
				'click: customizeDownload',
				customizeCompleteButton
			);
			this.setState({ showLoader: true });
			this.props.checkEntitlementAndGenerateUniqueKey(
				data,
				'custom',
				this.props.history
			);
		} else {
			this.setState({ open: true });
		}
	}

  handleClose() {
    this.setState({ open: false });
  }

  handleNext({ target }) {
    if (
      target &&
      target.getAttribute("disabled") &&
      target.getAttribute("disabled") === "disabled"
    ) {
      //Do Nothing
    } else {
      let $activeCard = $(".active");
      $(".card-carousel")
        .stop(false, true)
        .animate({ left: "-=" + ($(".ESHWrapper").outerWidth() / 2 + 24) });
      $activeCard.removeClass("active");
      $activeCard.siblings().removeClass("active");
      $activeCard.next().addClass("active");
      $(".card-carousel *").attr("disabled", "disabled");
      $(".active *").removeAttr("disabled");
      this.disableCheckBoxes();
      this.addCSSClassToIngredients();
    }
  }
  handleBack({ target }) {
    if (
      target &&
      target.getAttribute("disabled") &&
      target.getAttribute("disabled") === "disabled"
    ) {
      //Do Nothing
    } else {
      let $activeCard = $(".active");

      $(".card-carousel")
        .stop(false, true)
        .animate({ left: "+=" + ($(".ESHWrapper").outerWidth() / 2 + 24) });
        // .animate({});

      $activeCard.removeClass("active");
      $activeCard.siblings().removeClass("active");
      $activeCard.prev().addClass("active");
      $(".card-carousel *").attr("disabled", "disabled");
      $(".active *").removeAttr("disabled");
      this.disableCheckBoxes();
      this.addCSSClassToIngredients();
    }
  }

  handleOptInSelection() {
    this.setState({ optInForSubscription: !this.state.optInForSubscription });
  }

  generateIngredientsCard(customisedIngedients, downloadCardNumber) {
    if (customisedIngedients) {
      return Object.keys(customisedIngedients).map((keyName, i) =>
        this.renderContent(
          customisedIngedients[keyName],
          keyName,
          i,
          downloadCardNumber
        )
      );
    }
  }

  handleIngredientSelection = (e) => {
    let target = e.target;
    let dependencyIds = this.props.EIReducer.dependency;
    let data = {};
    if (target) {
      let selectedIngredientId = target.value;
      let selectedIngredient = {};
      let getSelectedIngredientsList = this.props.EIReducer.selectedIngredients;
      let downloadDetails = this.props.EIReducer.EIDownloadList;
      let query = { id: selectedIngredientId };
      if (target.checked) {
        selectedIngredient = downloadDetails.filter(function (item) {
          for (var key in query) {
            if (item[key] === undefined || item[key].indexOf(query[key]))
              return false;
          }
          return true;
        });
        if (selectedIngredient) {
          getSelectedIngredientsList.push(selectedIngredient[0]);
        }
        if (dependencyIds && dependencyIds.length) {
          dependencyIds.forEach((dependency) => {
            if (dependency.dependerId === selectedIngredientId) {
              if (document.getElementById(dependency.dependeeId)) {
                document.getElementById(dependency.dependeeId).checked = true;
                let alreadyExisted;
                let dependeeQuery = { id: dependency.dependeeId };
                selectedIngredient = downloadDetails.filter(function (item) {
                  for (var key in dependeeQuery) {
                    if (
                      item[key] === undefined ||
                      item[key].indexOf(dependeeQuery[key])
                    )
                      return false;
                  }
                  return true;
                });
                alreadyExisted = getSelectedIngredientsList.find(
                  (selIngre) => selIngre.id === dependency.dependeeId
                );
                if (!alreadyExisted) {
                  getSelectedIngredientsList.push(selectedIngredient[0]);
                }
              }
            }
          });
        }
      } else {
        const dependeeList = _.mapValues(
          _.groupBy(dependencyIds, "dependeeId"),
          (clist) =>
            clist.map((dependencyIds) => _.omit(dependencyIds, "dependeeId"))
        );
        let dependerIds = dependeeList[target.value];
        let hasDependency = false;
        if (dependerIds) {
          dependerIds.forEach((dependerId) => {
            let currentDependerId = dependerId.dependerId;
            if (
              document.getElementById(currentDependerId) &&
              document.getElementById(currentDependerId).checked
            ) {
              hasDependency = true;
            }
          });
        }

        if (hasDependency) {
          document.getElementById(target.value).checked = true;
        } else {
          selectedIngredient = getSelectedIngredientsList.filter(function (
            item
          ) {
            for (var key in query) {
              if (item[key] === undefined || item[key].indexOf(query[key]))
                return false;
            }
            return true;
          });
          if (selectedIngredient) {
            const index = getSelectedIngredientsList.indexOf(
              selectedIngredient[0]
            );
            if (index > -1) {
              getSelectedIngredientsList.splice(index, 1);
            }
          }
        }
      }
      data.selectedIngredientsXML = getSelectedIngredientsList;
      data.selectedIngredients = getSelectedIngredientsList;
      this.props.UpdateSelectedIngredients(data);
      this.disableDependentIngredient(getSelectedIngredientsList);
    }
  };
  htmlDecode(input) {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  handleClick = (event) => {
    var $spanElement = event.target.parentElement;
    var $container = $(event.target).closest("div.card-wrapper");
    if (
      $container.attr("disabled") ||
      $container.attr("disabled") === "disabled"
    ) {
    } else {
      let selectedIngredient = {};
      this.setState({ target: $spanElement, container: $container[0] });
      let allIngredients = this.props.EIReducer.EIDownloadList;

      allIngredients.map((option, index) => {
        if ($spanElement.id.includes(option.id)) {
          selectedIngredient = option;
        }
        return true;
      });
      let fullURL = selectedIngredient.moreInfoLink ?? "";
      var pathArray = "";
      var baseURL = "";
      if (fullURL) {
        pathArray = fullURL.split("/");
        baseURL = pathArray[0];
        if (fullURL.indexOf("//") > -1) {
          baseURL = pathArray[2];
        }
      }
      if (selectedIngredient && selectedIngredient.name) {
        var name = selectedIngredient.displayName[selectedLanguage]
          ? selectedIngredient.displayName[selectedLanguage]
          : selectedIngredient.displayName["en"];
        if (name === "" || name === undefined) {
          name = selectedIngredient.name[selectedLanguage]
            ? selectedIngredient.name[selectedLanguage]
            : selectedIngredient.name["en"];
        }
        let desc = selectedIngredient.moreInfo[selectedLanguage]
          ? selectedIngredient.moreInfo[selectedLanguage]
          : selectedIngredient.moreInfo["en"];
        desc = this.htmlDecode(desc);
        this.setState({
          show: true,
          selectedIngredientName: name,
          selectedIngredientDesc: desc,
          selectedIngredientMoreInfoLink: fullURL,
          baseURL: baseURL,
        });
        setTimeout(() => {
          var header = this.headerRef;
          var totalHeight = 280;
          if (this.state.selectedIngredientMoreInfoLink !== "") {
            totalHeight = 210;
          }
          this.setState({
            gradientTopHeight: header.current.clientHeight,
            popoverBodyHeight: totalHeight - header.current.clientHeight,
          });
        }, 300);
      }

      $("#popover-contained *").removeAttr("disabled");
    }
  };
  disableDependentIngredient(getSelectedIngredientsList) {
    disableCheckBoxList = [];
    let dependencyIds = this.props.EIReducer.dependency;
    if (!getSelectedIngredientsList)
      getSelectedIngredientsList = this.props.EIReducer.selectedIngredients;

    const dependeeList = _.mapValues(
      _.groupBy(dependencyIds, "dependeeId"),
      (clist) =>
        clist.map((dependencyIds) => _.omit(dependencyIds, "dependeeId"))
    );
    getSelectedIngredientsList.forEach((selectedIngredient) => {
      let dependerIds = dependeeList[selectedIngredient.id];
      if (dependerIds) {
        dependerIds.forEach((dependerId) => {
          let currentDependerId = dependerId.dependerId;
          if (
            document.getElementById(currentDependerId) &&
            document.getElementById(currentDependerId).checked
          ) {
            disableCheckBoxList.push(selectedIngredient.id);
          }
        });
      }
    });
    this.props.UpdateDisableRecipeList(disableCheckBoxList);
    this.disableCheckBoxes();
    this.addCSSClassToIngredients();
  }

  renderContent(Ingredient, keyName, i, downloadCardNumber) {
    const { t } = this.props;
    if (Ingredient.length) {
      let filteredIngredients = Ingredient;
      let getSelectedIngredientsList = this.props.EIReducer.selectedIngredients;
      let selectedIngredientIds = [];
      getSelectedIngredientsList.forEach((ingredient) =>
        selectedIngredientIds.push(ingredient.id)
      );
      let ingredientType = "";
      if (Ingredient && Ingredient[0].ingredientTypeName) {
        ingredientType = Ingredient[0].ingredientTypeName[selectedLanguage]
          ? Ingredient[0].ingredientTypeName[selectedLanguage]
          : Ingredient[0].ingredientTypeName["en"];
      }
      if (filteredIngredients.length) {
        return (
          <div id={i + 1}
            className={i === 0 ? "my-card active" : "my-card"}
            style={{
              marginLeft: "24px",
              width: $(".ESHWrapper").outerWidth() / 2,
            }}
          >
            
              <div className={styles.cardMainWrapper}>
                <div>
                  <div
                    className={styles.downloadSubHeading}
                    name="Download Sub Heading"
                  >
                    <div className={styles.pullLeft}>
                      <h3 className={styles.subHead}>
                        {i + 1}. {ingredientType}
                      </h3>
                    </div>
                    <div className={styles.downloadCardButton}>
                      <div className="pageIdentification">
                        {i + 1} of {downloadCardNumber}
                      </div>
                      <div  className="btnWrapper">
                        <Button kind="secondary" size="sm"
                          className="btnback"
                          onClick={
                            i === 0
                              ? this.props.history.goBack
                              : this.handleBack
                          }
                        >
                         {/*} <i className="fa fa-caret-left"></i>
                          <ArrowBackIos className={styles.fontsize20} />
                          <span
                            className={`${styles.positionback} ${styles.backFontColor}`}
                          >
                            Back
                          </span>*/}
                          Back
                        </Button>
                        <Button size="sm" className="btnnext" onClick={this.handleNext}>
                          {/* <span className={styles.positionback}>Next</span>{" "}
                          <i className="fa fa-caret-right"></i>
                          <ArrowForwardIos className={styles.fontsizeForward} /> */}
                          Next
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className={styles.greyText}>
                    {t("GRAYEDOUT_COMPONENTS")}
                  </div>
                </div>
              </div>

              <div className="ingredientContainer" name="Ingredient Container">
                {filteredIngredients.map((option, index) => (
                  <div
                    className={
                      selectedIngredientIds.includes(option.id)
                        ? "card-wrapper selected"
                        : "card-wrapper"
                    }
                  >
                    <div>
                      <p className={styles.optionHeading}>
                        {(option.displayName["en"] === "" ||
                          option.displayName["en"] === undefined) &&
                          (option.name[selectedLanguage] === undefined
                            ? option.name["en"]
                            : option.name[selectedLanguage])}
                        {(option.displayName["en"] !== "" ||
                          option.displayName["en"] !== undefined) &&
                          (option.displayName[selectedLanguage] === undefined
                            ? option.displayName["en"]
                            : option.displayName[selectedLanguage])}
                      </p>
                      <div className={styles.infoIconBox}>
                        <span ref={this.triggerRef}>
                          <span
                            className={styles.optionVersion}
                            id={"ingredient-" + option.id}
                          >
                            {/* <img
                              src={InfoOutlinedIcon}
                              alt="Info icon"
                              onClick={this.handleClick}
                              className={styles.infoIconStyle}
                            /> */}

                            <i
                              className={`spark-icon spark-icon-information-circle spark-icon-regular ${styles.infoIconStyle}`}
                              style={{ fontFamily: 'spark-icon' }}
                              onClick={this.handleClick}
                            />

                          </span>
                        </span>
                      </div>
                    </div>
                    <div>
                      <p className={styles.optionVersionleft}>
                        Version {option.version}
                      </p>
                    </div>
                    <p className={styles.optionDescription}>
                    <div 
                      dangerouslySetInnerHTML={{__html: option.desc[selectedLanguage] === undefined
                        ? sanitizeHtml(option.desc["en"])
                        : sanitizeHtml(option.desc[selectedLanguage])}}>
                      </div>
                      </p>
                    <div className={styles.cbox}>
                      {selectedIngredientIds.includes(option.id) ? (
                        
                        <Checkbox 
                        className={styles.CheckboxInput}
                        id={option.id}
                        value={option.id}
                        name="Checkbox"
                        label=""
                        data-wap_ref={
                              option.id +
                              "_" +
                              (option.displayName["en"] === ""
                                ? option.name["en"]
                                : option.displayName["en"]) +
                              "_Checked"
                            }
                            checked
                            onChange={this.handleIngredientSelection}
                        />

                        
                      ) : (
                        
                        <Checkbox 
                        className={styles.CheckboxInput}
                        id={option.id}
                        value={option.id}
                        name="Checkbox"
                        data-wap_ref={
                              option.id +
                              "_" +
                              (option.displayName["en"] === ""
                                ? option.name["en"]
                                : option.displayName["en"]) +
                              "_Unchecked"
                            }
                            onChange={this.handleIngredientSelection}
                        />
                      )}
                    </div>
                  </div>
                  
                ))}
                
              </div>
           
          </div>
        );
      } else {
        return false;
      }
    }
    return false;
  }

  clean = (obj) => {
    for (var propName in obj) {
      if (obj[propName].length === 0) {
        delete obj[propName];
      }
    }
    return obj;
  };

  render() {
    const { t } = this.props;
    let recipeVersions = [];
    let selectedOS = this.props.EIReducer.selectedOS;
    let ingredients = this.props.EIReducer.EIDownloadList;
    let selectedFilterOption = [];
    if (this.props.EIReducer.selectedFilterOption.length) {
      selectedFilterOption = this.props.EIReducer.selectedFilterOption;
    }
    let query = {};
    query = { osIds: selectedOS, hideDisplay: false };

    if (selectedFilterOption.length) {
      query.filterTags = selectedFilterOption;
    }
    selectedLanguage = this.props.EIReducer.selectedLanguage;
    if (
      this.props &&
      this.props.EIReducer &&
      this.props.EIReducer.EIDownloadList
    ) {
      if (this.props.EIReducer.recipeVersions.length) {
        recipeVersions = [];
        let allRecipeVersions = this.props.EIReducer.recipeVersions;
        const recipes = this.props.EIReducer.recipes;
        const recipeList = _.mapValues(_.groupBy(recipes, "id"), (clist) =>
          clist.map((recipes) => _.omit(recipes, "id"))
        );
        allRecipeVersions.forEach((recipeId) => {
          let currentRecipe = recipeList[recipeId.id];
          if (currentRecipe[0].isCustomizable) {
            recipeVersions.push(recipeId);
          }
        });
      }
    }
    let filteredIngredients = ingredients.filter((item) => this.filterIngredientList(item, query));
    var customisedIngedients = _.mapValues(
      _.groupBy(filteredIngredients, "ingredientType"),
      (clist) =>
        clist.filter((filteredIngredients) => {
          if (filteredIngredients.hideDisplay === false) {
            return _.omit(filteredIngredients, "ingredientType");
          } else {
            return false;
          }
        })
    );
    customisedIngedients = this.clean(customisedIngedients);
    let downloadCardNumber = Object.keys(customisedIngedients).length + 1;
    let getSelectedIngredientsList = this.props.EIReducer.selectedIngredients;
    if (
      Object.keys(this.state.checkboxes).length === 0 &&
      getSelectedIngredientsList
    ) {
      const selectedIngredientIds = [];
      let checkboxes = {};

      getSelectedIngredientsList.forEach((ingredient) =>
        selectedIngredientIds.push(ingredient.id)
      );
      filteredIngredients.map((option, index) => {
        if (selectedIngredientIds.includes(option.id)) {
          checkboxes[option.id] = true;
        } else {
          checkboxes[option.id] = false;
        }
        return true;
      });
    }
    const defaultStyle = {
      position: "relative",
      left: "0",
      overflow: "hidden",
    };
    const transitionStyles = {
      entering: {
        animation: "slideInFromBottom 1s forwards",
      },
      entered: { animation: "slideInFromBottom 1s forwards" },
    };

    let recipeName = this.props.EIReducer.EITitle[selectedLanguage]
      ? this.props.EIReducer.EITitle[selectedLanguage]
      : this.props.EIReducer.EITitle["en"];
    setTimeout(() => {
      var maxHeight = 0;
      $(".my-card").each(function () {
        if ($(this).height() > maxHeight) {
          maxHeight = $(this).height();
        }
      });
      $(".my-card").height(maxHeight);
    }, 200);
    return (
      <TransitionGroup>
        <ReactTransition
          key="vision"
          timeout={{
            enter: 1500,
          }}
        >
          {(status) => (
            <div
              style={{
                ...defaultStyle,
                ...transitionStyles[status],
              }}
            >

              <div id="MainContent" className={styles.bgcolor}>
                {this.state.showLoader === true ? (
                  <Loader />
                ) : (
                  <>
                    <Overlay
                      //ref={this.OverlayRef}
                      show={this.state.show}
                      // container={this.containerRef.current}
                      placement="top"
                      positionLeft={400}
                      data-container="body"
                      target={this.state.target}
                      containerPadding={20}
                      rootClose={true}
                      transition={false}
                      onHide={() => this.setState({ show: false })}
                    >
                      <Popover
                        id="popover-contained"
                        className={styles.popoverOverlay}
                      >
                        <Popover.Header as="div" ref={this.headerRef}>
                          <div>
                            <span className={styles.tooltipHeader}>
                              {this.state.selectedIngredientName}
                            </span>
                            <CloseIcon
                              className={styles.closeBtn}
                              onClick={this.closeOverlay}
                            />
                          </div>
                        </Popover.Header>
                        <Popover.Body
                          style={{ height: this.state.popoverBodyHeight }}
                        >
                          <p
                            className={styles.tooltip}
                            dangerouslySetInnerHTML={{
                              __html: this.state.selectedIngredientDesc,
                            }}
                          ></p>
                        </Popover.Body>

                        {this.state.selectedIngredientMoreInfoLink === "" && (
                          <div>
                            <div
                              className={styles.popoverGradientTop}
                              style={{ top: this.state.gradientTopHeight }}
                            ></div>
                            <div
                              className={styles.popoverGradientBottomNoMargin}
                            ></div>
                          </div>
                        )}
                        {this.state.selectedIngredientMoreInfoLink !== "" && (
                          <>
                            <div
                              className={styles.popoverGradientTop}
                              style={{ top: this.state.gradientTopHeight }}
                            ></div>
                            <div className={styles.popoverGradientBottom}></div>
                            <div class="popover-footer">
                              <span style={{ fontSize: 12, color: "#fff" }}>
                                Learn more at
                              </span>
                              <br />
                              <a
                                className={styles.refLinks}
                                href={this.state.selectedIngredientMoreInfoLink}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.baseURL}{" "}
                                <img
                                  src={LaunchIcon}
                                  alt="Launch icon"
                                  className={styles.iconStyle}
                                />
                              </a>
                            </div>
                          </>
                        )}
                      </Popover>
                    </Overlay>
                    <div className={styles.CustomTitleMainDiv}>
                      {/* <img
                        src={RecommendedImage}
                        className={styles.RecommendedImage}
                        alt="Recommended"
                      /> */}
                      <div className={styles.downloadCustomTitle}>
                        {t("CUSTOMIZE_YOUR")} {recipeName}
                      </div>
                    </div>
                    <div className="card-carousel">
                      {this.generateIngredientsCard(
                        customisedIngedients,
                        downloadCardNumber
                      )}
                      <div id={downloadCardNumber} className="my-card" style={{ marginLeft: "24px" }}>
                        <div className={styles.cardPadding}>
                          <div>
                            <div className={styles.downloadSubHeadingDownload}>
                              <div className={styles.pullLeft}>
                                <h3 className={styles.subHead}>
                                  {downloadCardNumber}. {t("DOWNLOAD")}
                                </h3>
                                <div>
                                  <p className={styles.downloadtext}>
                                    {t("DOWNLOAD_A_SMALL_INSTALLER")}
                                  </p>
                                </div>
                              </div>
                              <div className={styles.downloadCardButtonNew}>
                                <div className="pageIdentification">
                                  {downloadCardNumber} of {downloadCardNumber}
                                </div>
                                <div className="last-back-button">
                                <Button kind="secondary" size="sm" className="btnback" onClick={this.handleBack}>
                                  {/* <i className="fa fa-caret-left"></i>
                                  <ArrowBackIos className={styles.fontsize20} />
                                  <span
                                    className={`${styles.positionback} ${styles.backFontColor}`}
                                  > */}
                                    Back
                                  {/* </span> */}
                                </Button>
                                </div>
                              </div>
                            </div>

                            <div className={styles.downloadCardWrapper}>
                              <div className={styles.downloadbtnContainerFinal}>
                                <div class={styles.subscriptionstylesDiv}>
                                  <input
                                    id="optInForSubscription"
                                    value="optInForSubscription"
                                    type="checkbox"
                                    checked={this.state.optInForSubscription}
                                    onChange={this.handleOptInSelection}
                                  />
                                  <span className={styles.subscriptionstyles}>
                                    {t("YES_I_WOULD_LIKE_TO")}
                                  </span>
                                </div>
                                <Button
                                  id="defaultDownload"
                                  className={styles.downloadBtn}
                                  data-component="wa_skip_track"
                                  onClick={this.defaultDownload}
                                >
                                  {t("DOWNLOAD")}
                                </Button>
                              </div>
                            </div>
                            <div>
                              <span className={styles.ComponentsTitle}>
                                {t("INCLUDED_COMPONENETS")}
                              </span>
                              <EIIncludedComponents hideDownloadIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Dialog
                      open={this.state.open}
                      keepMounted
                      onClose={this.handleClose}
                      aria-labelledby="alert-dialog-slide-title"
                      aria-describedby="alert-dialog-slide-description"
                    >
                      <DialogTitle id="alert-dialog-slide-title">
                        {t("INTEL_DEV_CATALOG")}
                      </DialogTitle>
                      <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                          {t("PLEASE_CHOOSE_ATLEAST")}
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={this.handleClose} color="primary" kind="primary" size="md" className="dialogOkButton">
                          {t("OK")}
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                )}
              </div>
            </div>
          )}
        </ReactTransition>
      </TransitionGroup>
    );
  }

}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {
  UpdateEnsightContent,
  checkEntitlementAndGenerateUniqueKey,
  UpdateUserSelections,
  UpdateSelectedIngredients,
  UpdateDisableRecipeList,
})(withTranslation()(withRouter(CustomDownload)));
