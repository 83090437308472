/*
 * Copyright May 2020 Intel Corporation
 *
 * The source code contained or described herein and all documents related to the source code
 * ("Material") are owned by Intel Corporation or its suppliers or licensors. Title to the Material
 * remains with Intel Corporation or its suppliers and licensors. The Material may contain trade
 * secrets and proprietary and confidential information of Intel Corporation and its suppliers and
 * licensors, and is protected by worldwide copyright and trade secret laws and treaty provisions.
 * No part of the Material may be used, copied, reproduced, modified, published, uploaded, posted,
 * transmitted, distributed, or disclosed in any way without Intel's prior express written
 * permission.
 *
 * No license under any patent, copyright, trade secret or other intellectual property right is
 * granted to or conferred upon you by disclosure or delivery of the Materials, either expressly, by
 * implication, inducement, estoppel or otherwise. Any license under such intellectual property
 * rights must be express and approved by Intel in writing.
 */

import React from "react";
import { connect } from "react-redux";
import {
  GetUserData,
  SelectedLanguage,
  requestcnda,
  HandleLoader,
  Logout,
  updateCNDAService,
  UpdateNavigatedFromLicenseAgreementPage
} from "../../actions/EIAction";
import GlobalHeader from "../TiberHeader/TiberHeader"
import IdleTimer from "react-idle-timer";
import styles from "./header.module.css";
import { withRouter } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import i18n from "./../../i18n";
import { withTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import headerBanner from "../../resources/images/Tribber_edge_banner.svg";
// import actionBtnSlot from "../../resources/images/Action_button_Slot.svg";
import profile from "../../resources/images/Profile.svg";
import tribberTrademark from '../../resources/images/tribber_trade_mark.svg'
import EditIcon from "../../resources/images/edit_icon.svg";
import stackIcon from "../../resources/images/stack.svg";
import EditIconDisabled from "../../resources/images/edit_icon_disabled.svg";
import checkboxIcon from "../../resources/images/checkbox_unselected.svg";
import checkboxIconSelected from "../../resources/images/tribber_checkbox_selected.svg";
import RequestAccessDialog from "./RequestAccessDialog";
import Tooltip from "@material-ui/core/Tooltip";
import { generateBreadcrumb } from "../../components/Common/common";
import { HeroBanner } from "../HeroBanner/index";
import { Grid, Column, Heading, Stack, TextInput, Layer, Button as CarbonButton } from '@carbon/react'

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      profileMenu: true,
      open: false,
      errorMessage: "Use Chrome for a better experience.",
      collapse: false,
      collapseprofile: false,
      customFilters: {},
      openRequestAccessDialog: false,
      optInForSubscription: true
    };
    this.handleClose = this.handleClose.bind(this);
  }

  handleOptInSelection = () => {
    this.setState({ optInForSubscription: !this.state.optInForSubscription });
  };

  onAction(e) {
    //console.log('user did something', e)
  }

  onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  onIdle(e) {
    //navigate("/logout");
    document.getElementById("logo").click();
  }

  handleClose() {
    this.setState({ open: false });
  }

  handlerFunction = (e) => {
    //console.log(e.target.value)
  };

  languageHandler = async (lng) => {
    if (lng === "en") {
      i18n.changeLanguage(lng);
      this.setState({
        ...this.state,
        language: "USA (ENGLISH)",
        collapse: false,
      });
      await new Promise((r) => setTimeout(r, 200));
    } else if (lng === "ch") {
      i18n.changeLanguage(lng);
      this.setState({
        ...this.state,
        language: "Chinese (简体中文)",
        collapse: false,
      });
      await new Promise((r) => setTimeout(r, 200));
    }
    this.props.SelectedLanguage(lng);
  };

  componentDidUpdate(prevProps) {
    if (this.props.isSmmallHeader !== prevProps.isSmmallHeader) {
      this.handleResize();
    }
  }

  componentDidMount() {
    this.props.GetUserData();
    const URLParams = this.props.location.search;
    const isIE = /*@cc_on!@*/ false || !!document.documentMode;
    const isEdge = !isIE && !!window.StyleMedia;
    if (isIE && !isEdge) {
      this.setState({ open: true });
    }
    let filteredContent = this.props.EIReducer.filterContent;
    let selectedFilterOption = this.props.EIReducer.selectedFilterOption;
    let selectedFilterOptions = {
      customFilters: {},
    };

    filteredContent.forEach((filterOption, index) => {
      filterOption.tags.forEach((filterTags) => {
        selectedFilterOption.forEach((id) => {
          if (id === filterTags.id) {
            selectedFilterOptions.customFilters[filterOption.tagName["en"]] =
              filterTags.name["en"];
          }
        });
      });
    });
    this.setState({
      ...selectedFilterOptions,
    });
    //this.handleResize();
    window.addEventListener("resize", this.handleResize);
    const getCurrentLng = () =>
      i18n.language || window.localStorage.i18nextLng || "";
    let selectedLanguage = "en";
    if (getCurrentLng() === "ch") {
      this.setState({ language: "Chinese (简体中文)" });
      selectedLanguage = "ch";
    } else {
      this.setState({ language: "USA (ENGLISH)" });
    }

    if (URLParams.indexOf("zh-cn") > -1) {
      selectedLanguage = "ch";
      i18n.changeLanguage("ch");
      this.setState({ language: "Chinese (简体中文)" });
    } else if (URLParams.indexOf("en-") > -1) {
      selectedLanguage = "en";
      i18n.changeLanguage("en");
      this.setState({ language: "USA (ENGLISH)" });
    }
    this.props.SelectedLanguage(selectedLanguage);
    const euCountryList = [
      "AT",
      "BE",
      "BG",
      "HR",
      "CY",
      "CZ",
      "DK",
      "EE",
      "FI",
      "FR",
      "DE",
      "GR",
      "HU",
      "IE",
      "IT",
      "LV",
      "LT",
      "LU",
      "MT",
      "NL",
      "PL",
      "PT",
      "RO",
      "SK",
      "SI",
      "ES",
      "SE",
    ];
    const userCountry = this.props.EIReducer.session.country;
    if (euCountryList.indexOf(userCountry) >= 0) {
      this.setState({ optInForSubscription: false });
    }
    this.generateBreadcrumbFn();
  }

  generateBreadcrumbFn = () => {
    if (window.intel && window.intel.breadcrumbFromJSON) {
      const selectedLanguage = this.props.EIReducer.selectedLanguage;
      let EITitle = "";
      if (this.props.EIReducer.EITitle) {
        EITitle = this.props.EIReducer.EITitle[selectedLanguage];
        if (EITitle === undefined || EITitle === "") {
          EITitle = this.props.EIReducer.EITitle["en"];
        }
      }
      let data = {
        EITitle,
        pageName: "",
        history: this.props.history,
        homeURL: this.props.EIReducer.baseURL,
        logout: this.props.Logout,
        initialize: 1
      }
      generateBreadcrumb(data);
    } else {
      setTimeout(() => {
        this.generateBreadcrumbFn();
      }, 1000);

    }
  }

  handleResize() {
    if (document.getElementById("header")) {
      const headerHeight = document.getElementById("header").offsetHeight;
      const windowHeight = window.innerHeight;
      const footerHeight =
        document.getElementsByClassName("footerContainer")[0].offsetHeight;
      const contentHeight = windowHeight - (headerHeight + footerHeight);
      document.getElementById("ESHWrapper").style.minHeight =
        contentHeight + "px";
    }
  }

  editFilters = () => {
    if (this.props.EIReducer.recipeClass === "Online Distribution") {
      this.props.UpdateNavigatedFromLicenseAgreementPage(true)
    }
    let baseURL = this.props.EIReducer.baseURL;
    this.props.history.push(baseURL);
  };

  getImageName = () =>
    this.state.collapseprofile ? "profile" : "profilevisited";
  getImageNamelang = () =>
    this.state.collapse ? "language" : "languagevisited";

  openRequestAccessDialog = async () => {
    let data = {};
    data.recipeId = this.props.EIReducer.recipeId;
    data.ircId = this.props.EIReducer.ircProductId;
    let serviceCallStatus = await this.props.requestcnda(data);
    if (serviceCallStatus) {
      this.setState({ openRequestAccessDialog: true });
    }
  };
  closeRequestAccessDialog = () => {
    this.setState({ openRequestAccessDialog: false });
  };
  render() {
    const { t } = this.props;
    const found = this.props.isSmmallHeader;
    const selectedLanguage = this.props.EIReducer.selectedLanguage;
    const URLParams = this.props.location.search;
    const recipeClass = this.props.EIReducer.recipeClass
    let licenseType = this.props.EIReducer.licenceType;
    let isCNDALicenseApproved = this.props.EIReducer.isCNDALicenseApproved;
    let RequestAccessButtonFlag = false;
    let headerWrapperHeight = "";
    let headerContainerHeight = "";
    let backgroundImageHeightWithRequestAccessBtn = 216;
    let headerMenuHeight = 64;
    let eloquaTextHeight = 56;
    let isCNDArequestRaised = this.props.EIReducer.isCNDArequestRaised;
    let currentLocation = window.location.pathname;
    let isDownloadPage = currentLocation.indexOf("downloadrecipe") > -1
    if (
      licenseType.toLowerCase() === "cnda" &&
      isCNDALicenseApproved === false &&
      this.props.isSmmallHeader === false &&
      this.props.EIReducer.isCNDAVerifyEntitlementCompleted
    ) {
      RequestAccessButtonFlag = true;
      headerWrapperHeight =
        backgroundImageHeightWithRequestAccessBtn +
        headerMenuHeight +
        eloquaTextHeight;
      headerContainerHeight = backgroundImageHeightWithRequestAccessBtn;
      if (isCNDArequestRaised) {
        headerWrapperHeight =
          backgroundImageHeightWithRequestAccessBtn + headerMenuHeight;
        headerContainerHeight = backgroundImageHeightWithRequestAccessBtn;
      }
    }
    let EITitle = "";
    if (this.props.EIReducer.EITitle) {
      EITitle = this.props.EIReducer.EITitle[selectedLanguage];

      if (EITitle === undefined || EITitle === "") {
        EITitle = this.props.EIReducer.EITitle["en"];
      }
    }
    let recipeDesc = this.props.EIReducer.EIDesc[selectedLanguage];
    if (recipeDesc === undefined) {
      recipeDesc = this.props.EIReducer.EIDesc.en;
    } else if (recipeDesc && recipeDesc.indexOf("\n\n") > -1) {
      recipeDesc = recipeDesc.replaceAll("\n\n", "\n");
    }
    let recipeType = this.props.EIReducer.recipeClass;
    let customFilterSelections = this.state.customFilters;
    let distributionType = t("CUSTOMIZE_DOWNLOAD");
    if (this.props.EIReducer.downloadType === "default") {
      distributionType = t("DOWNLOAD_RECOMMENDED_CONFIGURATION");
    }
    let languageCode = "en_US";
    if (URLParams.indexOf("zh-cn") > -1 || selectedLanguage === "ch") {
      languageCode = "zh_CN";
    }
    /*<![CDATA[*/
    window.INTELNAV = window.INTELNAV || {}; window.INTELNAV.renderSettings = { version: "2.0 - 03/12/2017 08:00:00", textDirection: "ltr", culture: languageCode, OutputId: "default" };
    /*]]>*/
    return (<>
      <div
        id="header"
        className={found ? styles.SmallHeaderWrapper : styles.headerWrapper}
      >
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 10}
        />
        <div className={styles.headerTop} >
          <div style={{display: "none"}} id="recode50header" className="no-animate"></div>
          <GlobalHeader logout={this.props.Logout} />
        </div>
        {/* Banner */}
        <div
          className={styles.container}
        >
          <HeroBanner variant='primary'>
            <Grid condensed={true} fullWidth={true} style={{ gap: '16px', alignItems: 'center', padding: 0 }} >
              <Column lg={ found ? 8: 12 } md={8} sm={4}> 
                <Stack gap={3} orientation="vertical">
                  <Heading className="hero-banner-text" size="m" color='inverse' > {EITitle} </Heading>
                  <Layer>
                    <Stack className="stack-desc" orientation="horizontal" gap={2}>
                      { found ? null : <p className={`spark-text-size-l ${styles.siteTitleDescription}`}> {(recipeDesc ? recipeDesc.replace(/(<([^>]+)>)/gi, "") : recipeDesc)}</p>}
                    </Stack>
                  </Layer>
                </Stack>
              </Column>
              <Column lg={8} md={8} sm={4}>
                  {found ? <Stack className="stack-edit" orientation="horizontal" gap={3}>
                            {(recipeType !== "Online Distribution") && 
                                <TextInput size="sm" 
                                  labelText={t("TARGET_SYSTEM")} 
                                  defaultValue={this.props.EIReducer.selectedOSName}
                                  readOnly = {true} 
                                  className="white-text">
                                </TextInput>}
                              <TextInput size="sm" 
                                labelText={t("VERSION")} 
                                defaultValue={this.props.EIReducer.selectedRecipeName}
                                readOnly = {true} 
                                className="white-text">
                              </TextInput>
                              {Object.keys(customFilterSelections).map((oneKey, i) => (
                                <TextInput size="sm" 
                                  labelText={oneKey} 
                                  defaultValue={customFilterSelections[oneKey]}
                                  readOnly = {true} 
                                  className="white-text">
                                </TextInput>
                              ))}
                              {(recipeType !== "Online Distribution") && 
                                <TextInput size="sm" 
                                  labelText={t("DISTRIBUTION")} 
                                  defaultValue={distributionType}
                                  readOnly = {true} 
                                  className="white-text">
                                </TextInput>}
                                {(isDownloadPage && this.props.EIReducer.PackagingPercentage !== 100) ? null : <CarbonButton kind="secondary" size="sm" renderIcon={() => 
                                                  <i
                                                      className="spark-icon spark-icon-pencil spark-icon-light"
                                                      style={{ fontFamily: 'spark-icon' }}
                                                  />
                                } style={{
                                '--cds-spark-button-primary': 'white',
                                blockSize: 'min-content',
                                alignSelf: 'flex-end'
                              }}
                              onClick={(isDownloadPage === false || this.props.EIReducer.PackagingPercentage === 100) && this.editFilters}
                              className={"editOption"}>
                                  Edit{' '}
                              </CarbonButton>}
                  </Stack>: 
                    null
                  }
              </Column>
            </Grid>
          </HeroBanner>
        </div>
        {/* Banner End */}
        {RequestAccessButtonFlag &&
          (<div className={styles.SubscribeTextDiv}>
            {RequestAccessButtonFlag &&
              (isCNDArequestRaised === false ? (
                <div className={styles.RequestAccessButtonDiv}>
                  <Button
                    className={styles.RequestAccessButton}
                    onClick={this.openRequestAccessDialog}
                    color="primary"
                    disabled={this.props.EIReducer.isCNDAVerifyEntitlementCompleted ? false : true}
                  >
                    <div style={{
                      //styleName: Button/Button Large;
                      fontFamily: "IntelOne Text",
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      textAlign: 'left',
                      textTransform: 'capitalize'
                    }}  >{t("REQUEST_ACCESS")}</div>
                  </Button>
                </div>
              ) : (
                <div className={styles.RequestAccessButtonDiv}>
                  <Button className={styles.RequestAccessButtonDisabled}>
                    {t("ACCESS_REQUEST_RECEIVED")}
                  </Button>
                  <span className={styles.approvalText}>
                    {t("REQUEST_ACCESS_DIALOG_CONTENT")}
                  </span>
                </div>
              ))}
            {RequestAccessButtonFlag &&
              (isCNDArequestRaised === false ? (
                <React.Fragment>
                  <div>
                    <Checkbox
                      id="optInForSubscription"
                      value="optInForSubscription"
                      color="primary"
                      checked={this.state.optInForSubscription}
                      onChange={this.handleOptInSelection}
                      className={styles.SubscribeCheckBox}
                      icon={<img src={checkboxIcon} alt="checkbox"
                        style={{
                          filter: 'invert(85%) sepia(99%) saturate(178%) hue-rotate(246deg) brightness(116%) contrast(100%)',
                          height: '15px',
                          width: '15px'
                        }} />}
                      checkedIcon={
                        <img src={checkboxIconSelected} alt="checkbox"
                          style={{
                            height: '15px',
                            width: '15px'
                          }}
                        />
                      }
                    />
                  </div>
                  <span className={styles.optInText}>
                    {t("YES_I_WOULD_LIKE_TO")}
                  </span>
                </React.Fragment>
              ) : (
                ""
              ))}
          </div>)}
        <RequestAccessDialog
          openDialog={this.state.openRequestAccessDialog}
          closeRequestAccessDialog={this.closeRequestAccessDialog}
          CNDAServiceResponse={this.props.EIReducer.CNDAServiceResponse}
        />
        <Dialog
          open={this.state.open}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {t("BROWSER_NOT_SUPPORTED")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {
  GetUserData,
  SelectedLanguage,
  requestcnda,
  HandleLoader,
  Logout,
  updateCNDAService,
  UpdateNavigatedFromLicenseAgreementPage,
})(withTranslation()(withRouter(Header)));

