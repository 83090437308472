import React from 'react';
import "./App.scss"
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { RemoveErrorMessage, ClearRecipeDetails } from "./actions/EIAction"
import AppRouter from "./components/AppRouter/AppRouter";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";
import AlertFailure from './components/Alerts/AlertFailure';
import './App.scss';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showError: false,
      showLoader: false
    }
  }
  logout = () => {
    this.props.ClearRecipeDetails();
  }

  // Setup the `beforeunload` event listener
  setupBeforeUnloadListener = () => {
    window.addEventListener("beforeunload", (ev) => {
      ev.preventDefault();
      return this.logout();
    });
  };
  componentDidUpdate(prevProps) {
    if (this.props.EIReducer.error !== prevProps.EIReducer.error && this.props.EIReducer.error === true) {
      this.setState({ showError: true });
      setTimeout(() => {
        this.setState({ showError: false });
        this.props.RemoveErrorMessage();
      }, 6000);
    }
  }

  componentDidMount(){
    this.setupBeforeUnloadListener();
  }
  render() {
    let currentLocation = window.location.pathname;
    const array1 = ["customdownload", "downloadrecipe", "licenseagreement"];
    const found = array1.find(element => currentLocation.indexOf(element) > -1);
    let isSmmallHeader = false;
    if (found) {
      isSmmallHeader = true;
    }
    return (
      <div className="ESHMain" data-theme="light" data-color-scheme-fix="tiber">

        {this.state.showError && <AlertFailure message={this.props.EIReducer.message} />}
        <BrowserRouter basename="/">
          <Header isSmmallHeader={isSmmallHeader} />
          <div id="ESHWrapper" className="ESHWrapper">
            <AppRouter />
          </div>
        </BrowserRouter>
        <Footer />
      </div>
    );
  }

}
const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});

export default connect(mapStateToProps, { RemoveErrorMessage, ClearRecipeDetails })(App);
