import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  UpdateEnsightContent,
  UpdateSelectedIngredients,
} from "./../../actions/EIAction";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./EIIncudedComponents.module.css";
import InfoOutlinedIcon from "../../resources/images/info_circle.svg";
import { Overlay, Popover } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import LaunchIcon from "../../resources/images/ic_link_76DAFF.svg";
import _ from "lodash";
import sanitizeHtml from 'sanitize-html';

class EIIncludedComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      errorMessage: "",
      isCloseOptionRequired: false,
      show: false,
      target: "",
      selectedIngredientName: "",
      selectedIngredientDesc: "",
      selectedIngredientMoreInfoLink: "",
      baseURL: "",
      gradientTopHeight: 0,
      popoverBodyHeight: 160,
      hover: false,
      filteredIngredients: [],
    };
    this.triggerRef = createRef();
    this.containerRef = createRef();
    this.headerRef = React.createRef();
    this.handleClose = this.handleClose.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.closeOverlay = this.closeOverlay.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
  }
  componentDidUpdate(prevProps) {
    const hasAChanged =
      this.props.EIReducer.selectedIngredients !==
      prevProps.EIReducer.selectedIngredients;
    if (hasAChanged) {
      this.setState({
        filteredIngredients: this.props.EIReducer.selectedIngredients,
      });
    }
  }

  componentDidMount() {
    let currentPath = window.location.href;
    this.setState({
      filteredIngredients: this.props.EIReducer.selectedIngredients,
    });
    if (currentPath.indexOf("/customdownload") > -1) {
      //isCloseOptionRequired = true;
      this.setState({ isCloseOptionRequired: true });
    } else {
      //isCloseOptionRequired = false;
      this.setState({ isCloseOptionRequired: false });
    }
  }
  handleClose() {
    this.setState({ open: false });
  }

  toggleHover(e) {
    this.setState({ target: e.target.parentElement });
    this.setState({ hover: !this.state.hover });
  }

  filterIngredients(ingredients) {
    return Object.keys(this).every((key) =>
      ingredients[key].indexOf(this[key])
    );
  }

  htmlDecode(input) {
    var e = document.createElement("textarea");
    e.innerHTML = input;
    // handle case of empty input
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }
  
  handleClick = (event) => {
    this.setState({ target: event.target });
    let selectedLanguage = this.props.EIReducer.selectedLanguage;
    var getSelectedIngredientsList = this.props.EIReducer.selectedIngredients;
    var id = event.target.parentElement.id;
    let fullURL = getSelectedIngredientsList[id].moreInfoLink;
    var pathArray = "";
    var baseURL = "";
    if (fullURL) {
      pathArray = fullURL.split("/");
      baseURL = pathArray[0];
      if (fullURL.indexOf("//") > -1) {
        baseURL = pathArray[2];
      }
    }

    if (getSelectedIngredientsList[id]) {
      var name = getSelectedIngredientsList[id].displayName[selectedLanguage]
        ? getSelectedIngredientsList[id].displayName[selectedLanguage]
        : getSelectedIngredientsList[id].displayName["en"];
      if (name === "" || name === undefined) {
        name = getSelectedIngredientsList[id].name[selectedLanguage]
          ? getSelectedIngredientsList[id].name[selectedLanguage]
          : getSelectedIngredientsList[id].name["en"];
      }
      var desc = getSelectedIngredientsList[id].moreInfo[selectedLanguage]
        ? getSelectedIngredientsList[id].moreInfo[selectedLanguage]
        : getSelectedIngredientsList[id].moreInfo["en"];
      desc = this.htmlDecode(desc);
      this.setState({
        selectedIngredientName: name,
        selectedIngredientDesc: desc,
        selectedIngredientMoreInfoLink: fullURL,
        baseURL: baseURL,
      });
      this.setState({ show: true });
      setTimeout(() => {
        var header = this.headerRef;
        if (header && header.current) {
          var totalHeight = 280;
          if (this.state.selectedIngredientMoreInfoLink !== "") {
            totalHeight = 210;
          }
          this.setState({ gradientTopHeight: header.current.clientHeight });
          this.setState({
            popoverBodyHeight: totalHeight - header.current.clientHeight,
          });
        }
      }, 100);
    }
  };

  closeOverlay() {
    this.setState({
      show: false,
    });
  }

  render() {

    const { t } = this.props;
    let selectedLanguage = this.props.EIReducer.selectedLanguage;
    let recipeClass = this.props.EIReducer.recipeClass;
    let chinaWarningMessage = false;
    if (recipeClass === "vision") {
      chinaWarningMessage = true;
    }
    var gridSelectionWrapper = document.getElementById('gridSelectionWrapper');
    let gridSelectionWrapperHeight = 0
    if (gridSelectionWrapper) {
      gridSelectionWrapperHeight = gridSelectionWrapper.offsetHeight;
    }

    let filteredIngredients = this.state.filteredIngredients;
      filteredIngredients = filteredIngredients.filter((ingredients) => {
        if (ingredients.hideDisplay === false) {
          return _.omit(ingredients, "ingredientType");
        } else {
          return false;
        }
      });
    return (
      <>
        <div
          className={
            this.state.isCloseOptionRequired === true
              ? styles.customDownloadList
              : styles.downloadList
          }
          style = {{minHeight:this.state.isCloseOptionRequired === false ? gridSelectionWrapperHeight: "auto"}}
          name="download list"
          ref={this.containerRef}
        >
          <div
            className={`${styles.rows} ${this.state.isCloseOptionRequired ? "" : styles.displayFlex
              }`}
          >
            <div
              className={
                this.state.isCloseOptionRequired
                  ? styles.customModuleList
                  : styles.moduleList
              }
            >
              <>

                {recipeClass !== "Online Distribution" &&
                  filteredIngredients.map(
                    (downloadItemList, index) => (
                      downloadItemList.hideDisplay === false && (
                      <div
                        className={
                          this.props.hideDownloadIcon === true
                            ? styles.listItemnew
                            : styles.listItem
                        }
                        key={index}
                        name="Component List"
                      >
                        <p key={index}>
                          {(downloadItemList.displayName["en"] !== "" ||
                            downloadItemList.displayName["en"] !== undefined) &&
                            (downloadItemList.displayName[selectedLanguage]
                              ? downloadItemList.displayName[selectedLanguage]
                              : downloadItemList.displayName["en"])}
                          {(downloadItemList.displayName["en"] === "" ||
                            downloadItemList.displayName["en"] === undefined) &&
                            (downloadItemList.name[selectedLanguage]
                              ? downloadItemList.name[selectedLanguage]
                              : downloadItemList.name["en"])}
                        </p>
                        {this.state.isCloseOptionRequired === false && (
                          <span
                            id={index}
                            ref={this.triggerRef}
                            style={{ marginRight: 15 }}
                          >
                            <img
                              src={InfoOutlinedIcon}
                              alt="Info icon"
                              onClick={this.handleClick}
                              className={styles.infoIconStyle}
                            />
                          </span>
                        )}
                      </div>
                    ))
                  )}
              </>
              {recipeClass === "Online Distribution" &&
                this.state.filteredIngredients.length > 1 && (
                  <div className={styles.productKeyWrapper}>
                    <span>Please select more Filter options</span>
                  </div>
                )}
              {recipeClass === "Online Distribution" &&
                this.state.filteredIngredients.length === 0 && (
                  <div className={styles.productKeyWrapper}>
                    <span>No records for these filter selections</span>
                  </div>
                )}
              {chinaWarningMessage &&
                this.state.isCloseOptionRequired === false && (
                  <p className={styles.chinaWarning}>
                    {t("MODULE_AVAILABILITY_RESTRICTED_IN_CHINA")}
                    <a
                      className={styles.refLinks1}
                      href={
                        "https://software.intel.com/content/www/us/en/develop/documentation/edge-insights-vision-doc/top.html"
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("A_LIST_OF_AVAILABLE_MODULES_AND_KNOWN_ISSUES")}
                      <img
                        src={LaunchIcon}
                        alt="Launch icon"
                        className={styles.launchIcon}
                      />
                    </a>
                  </p>
                )}
            </div>
          </div>
        </div>
        <Dialog
          open={this.state.open}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {t("ESB_DOWNLOADER")}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {this.state.errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              {t("OK")}
            </Button>
          </DialogActions>
        </Dialog>
        <Overlay
          //ref={this.OverlayRef}
          show={this.state.show}
          // container={this.containerRef.current}
          placement="top"
          positionLeft={400}
          data-container="body"
          target={this.state.target}
          containerPadding={20}
          rootClose={true}
          transition={false}
          onHide={() => this.setState({ show: false })}

        >
          <Popover id="popover-contained" className={styles.popoverOverlay}>
            <Popover.Header as="div" ref={this.headerRef}>
              <div>
                <span className={styles.tooltipHeader}>
                  {this.state.selectedIngredientName}
                </span>
                <CloseIcon
                  className={styles.closeBtn}
                  onClick={this.closeOverlay}
                />
              </div>
            </Popover.Header>
            <Popover.Body style={{ height: this.state.popoverBodyHeight }}>
              <p
                className={styles.tooltip}
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(this.state.selectedIngredientDesc),
                }}
              ></p>
            </Popover.Body>

            {this.state.selectedIngredientMoreInfoLink === "" && (
              <div>
                <div
                  className={styles.popoverGradientTop}
                  style={{ top: this.state.gradientTopHeight }}
                ></div>
                <div className={styles.popoverGradientBottomNoMargin}></div>
              </div>
            )}
            {this.state.selectedIngredientMoreInfoLink !== "" && (
              <>
                <div
                  className={styles.popoverGradientTop}
                  style={{ top: this.state.gradientTopHeight }}
                ></div>
                <div className={styles.popoverGradientBottom}></div>
                <div class="popover-footer" style={{ backgroundColor: '#262626' }}>

                  <span style={{ fontSize: 12, color: "#fff" }}>
                    Learn more at
                  </span>
                  <br />
                  <a
                    className={styles.refLinks}
                    href={this.state.selectedIngredientMoreInfoLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {this.state.baseURL}{" "}
                    <img
                      src={LaunchIcon}
                      alt="Launch icon"
                      className={styles.iconStyle}
                    />
                  </a>
                </div>
              </>
            )}
          </Popover>
        </Overlay>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  EIReducer: state.EIReducer,
});
export default connect(mapStateToProps, {
  UpdateEnsightContent,
  UpdateSelectedIngredients,
})(withTranslation()(EIIncludedComponents));
