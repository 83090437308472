// Copyright 2020 Intel Corporation.

// This software and the related documents are Intel copyrighted materials,
// and your use of them is governed by the express license under which they
// were provided to you ("License"). Unless the License provides otherwise,
// you may not use, modify, copy, publish, distribute, disclose or transmit
// this software or the related documents without Intel's prior written
// permission.

// This software and the related documents are provided as is, with no express
// or implied warranties, other than those that are expressly stated in the License.

import React from "react";
import { Switch, Route } from "react-router-dom";
import Recipes from "../../pages/Packages/Packages";
import CustomDownload from "../../pages/CustomDownload/CustomDownload";
import LicenseAgreement from "../../pages/LicenseAgreement/LicenseAgreement";
import DownloadRecipe from "../../pages/DownloadRecipe/DownloadRecipe";
import ServiceUnavailable from "../../pages/ServiceUnavailable/ServiceUnavailable";
import Page404 from "../../pages/404/page404";
/* import ReferenceImplementation from "../../pages/Ri/Ri"; */

const approuter = () => (
  <Switch>
    {/* <Route exact path="/packages/:id" component={Recipes} /> */}
    <Route exact path="/customdownload" component={CustomDownload} />
    <Route exact path="/downloadrecipe/:id" component={DownloadRecipe} />
    <Route exact path="/licenseagreement/:id" component={LicenseAgreement} />
    <Route exact path="/serviceunavailable" component={ServiceUnavailable} />
    <Route exact path="/ri/:id" component={Recipes} />
    <Route exact path="/404" component={Page404}/>
    <Route exact path="/:id" component={Recipes} />
    <Route path="*" component={Page404} />
  </Switch>
);
export default approuter;
